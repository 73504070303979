var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-date-picker", {
    class: ["iq-date-picker", _vm.colorTypeClass],
    attrs: {
      disabled: _vm.disabled,
      type: _vm.type,
      placeholder: _vm.placeholder,
      format: _vm.format,
      "picker-options": _vm.options,
    },
    on: { change: _vm.handleChange, blur: _vm.handleBlur },
    model: {
      value: _vm.inputVal,
      callback: function ($$v) {
        _vm.inputVal = $$v
      },
      expression: "inputVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }