<template>
  <el-date-picker
    v-model="inputVal"
    :disabled="disabled"
    :class="['iq-date-picker', colorTypeClass]"
    :type="type"
    :placeholder="placeholder"
    :format="format"
    :picker-options="options"
    @change="handleChange"
    @blur="handleBlur"
  />
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      require: true,
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy',
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'date',
    },
    colorTypeClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    document
      .querySelectorAll('.el-date-editor input')
      .forEach(e => (e.readOnly = true))
  },
  methods: {
    handleChange(event) {
      this.$emit('onChange', event)
    },
    handleBlur(event) {
      this.$emit('onBlur', event)
    },
  },
}
</script>

<style lang="sass">
.iq-date-picker
  .info-message
    font-size: 11px
    color: $btn-orange!important
  .el-form-item__label
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: normal !important
    font-size: 14px !important
    line-height: 22px
    color: #606266
  .el-form-item__label:before
    content: '' !important
  .el-select-dropdown
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))
    border: none
    box-shadow: none
  .el-select-dropdown__item.selected
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $color-blue-primary

  &.gray
    .el-input__inner
      background: #F5F7FA
      border: 1px solid #DCDFE6
      border-radius: 4px
      font-weight: 500
      font-size: 14px
      line-height: 22px
      font-feature-settings: 'tnum' on, 'lnum' on
      color: #606266
      &::placeholder
        color: #606266
    &.el-form-item
      margin-bottom: 20px!important
    .el-input__suffix
      display: flex
      justify-content: center
      align-items: center
    .el-input__suffix-inner
      height: 100%
      display: flex
      justify-content: center
      align-items: center
    &.el-input--suffix .el-input__inner
      padding-right: 60px
    .el-input__suffix
      right: 13px
</style>
